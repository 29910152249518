import { action, createAsync, redirect, useParams } from '@solidjs/router';
import { For, Show, createResource, createSignal, Suspense, Switch, Match, createMemo } from 'solid-js';
import { Button, Form, Label, Link, Radio, RadioGroup, Separator, useToast } from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import { Title } from '@solidjs/meta';
import { gql, RedeemableRewardType, getApiClient } from '../../../graphql';
import { Content } from '../../../components/content';
import { Grid, GridMain, GridSidebar } from '../../../components/layouts/grid';
import { PaymentInfo } from '../../../components/payment-info';
import { CreditCardSelector } from '../../../components/credit-card-selector';
import { useUser } from '../../../providers/user';
import { cachedQuery } from '../../../graphql/cached-get';
import { revalidate } from '../../../graphql/cache';
import { FacilityReservationHeader } from '../../../components/facility/reservation-header';
import type { AddToast } from '@troon/ui';
import type { Track } from '@troon/analytics';
import type { ReservationHeaderFragment, ReservationUser } from '../../../graphql';

const startCheckin = async function startCheckin({
	reservationId,
	rewardType,
}: {
	reservationId: string;
	rewardType?: RedeemableRewardType;
}) {
	const res = await getApiClient().mutation(startCheckInMutation, { reservationId, rewardType });
	return { error: res.error?.graphQLErrors, data: res.data?.startCheckIn };
};

export default function Reservation() {
	const user = useUser();
	const trackEvent = useTrackEvent();
	const params = useParams<{ reservationId: string }>();
	const addToast = useToast();
	const reservation = createAsync(() => getReservation({ id: params.reservationId }));
	const [rewardType, setRewardType] = createSignal<RedeemableRewardType | undefined>(undefined);
	const [checkin] = createResource(
		() => ({ reservationId: params.reservationId, rewardType: rewardType() }),
		startCheckin,
	);

	const handleCheckIn = createCheckIn(trackEvent, addToast);

	const isCheckedIn = createMemo(() => {
		return reservation()?.reservation.users.find(({ user: reservationUser }) => reservationUser?.id === user()?.me.id)
			?.isCheckedIn;
	});

	return (
		<Content>
			<Title>Check in to your tee time reservation | Troon</Title>
			<Form document={finalizeCheckInMutation} action={handleCheckIn} method="post">
				<FacilityReservationHeader
					bordered
					reservation={() => reservation()?.reservation as ReservationHeaderFragment | undefined}
					user={() =>
						reservation()?.reservation.users.find((u) => u.user?.id && u.user?.id === user()?.me.id) as
							| ReservationUser
							| undefined
					}
				/>

				<Grid>
					<GridMain border class="flex flex-col gap-4 p-4 md:p-6">
						<h2 class="text-lg font-semibold">Choose payment method</h2>

						<RadioGroup name="rewardType">
							<Label>Choose how to pay</Label>
							<Radio checked={!rewardType()} onChange={() => setRewardType(undefined)} value="">
								<Label>Credit card</Label>
							</Radio>

							<Show when={!rewardType() || rewardType() === RedeemableRewardType.DiscountedRound}>
								<div class="ps-8">
									<CreditCardSelector cards={reservation()?.creditCards ?? []} name="creditCardId" />
								</div>
							</Show>

							<Show when={reservation()?.reservationRedeemableRewards?.length}>
								<Separator class="my-8 text-sm font-semibold uppercase text-neutral-800">Or use points</Separator>
							</Show>

							<For each={reservation()?.reservationRedeemableRewards} fallback={null}>
								{(rewards, index) => (
									<>
										{index() === 0 ? (
											<p class="rounded bg-neutral-500 px-4 py-2">
												You have <b class="font-bold">{reservation()!.userRewards.availablePoints}</b> points available
												that can be used towards booking this tee time.
											</p>
										) : null}
										<Radio onChange={() => setRewardType(rewards.type)} value={rewards.type}>
											<Label>{redeemTypeToString[rewards.type]}</Label>
											<p>
												{rewards.points} points{' '}
												<Show when={rewards.cash.value > 0}> + {rewards.cash.displayValue}</Show>
											</p>
										</Radio>
									</>
								)}
							</For>
						</RadioGroup>
					</GridMain>

					<GridSidebar border class="flex flex-col p-4 md:p-6">
						<Switch>
							<Match when={isCheckedIn()}>
								<h2 class="text-lg font-semibold">Checked in</h2>
								<p class="mb-4">You’re all checked in!</p>
								<Button as={Link} href={`/reservation/${params.reservationId}`}>
									Back to reservation
								</Button>
							</Match>
							<Match when={!isCheckedIn()}>
								<Suspense>
									<PaymentInfo
										receipt={checkin()?.data?.receipt}
										isCheckIn
										fallbackContent="Select payment method to continue…"
										supportsTroonRewards={reservation()?.reservation.course.supportsTroonRewards}
									/>

									<div class="mt-8">
										<input type="hidden" name="reservationId" value={params.reservationId} />
										<input type="hidden" name="courseId" value={reservation()?.reservation.courseId} />
										<Show when={checkin.latest?.error}>
											<p class="text-red-500">
												{
													// @ts-expect-error
													checkin.latest?.error[0].displayMessage ?? checkin.latest!.error[0].message
												}
											</p>
										</Show>

										<Button type="submit">Check in & pay</Button>
									</div>
								</Suspense>
							</Match>
						</Switch>
					</GridSidebar>
				</Grid>
			</Form>
		</Content>
	);
}

const reservationQuery = gql(`
query reservationCheckIn($id: String!) {
  reservation(id: $id) {
    teeTimeId
    ownerId
    state
		courseId
    playerCount
    holeCount
    includesCart
    isCheckInWindow
    dayTime {
      ...DayTime
    }
    isToday
    facility {
			...FacilityHeader
    }
    users {
			user {
				id
			}
      state
      isCheckedIn
    }
		course {
			supportsTroonRewards
		}
  }
  userRewards {
    availablePoints
  }
  reservationRedeemableRewards(reservationId: $id) {
    points
    type
    cash {
			value
      displayValue
    }
  }
  creditCards {
    id
    lastFour
    brand
  }
}
`);

const getReservation = cachedQuery(reservationQuery);

const startCheckInMutation = gql(`
mutation startCheckIn($reservationId: String!, $rewardType: RedeemableRewardType) {
  startCheckIn(reservationId: $reservationId, rewardType: $rewardType) {
    receipt {
    	...PaymentInfo
    }
    rewardPointsUsed
    rewardPointsEarned
  }
}
`);

const redeemTypeToString: Record<RedeemableRewardType, string> = {
	[RedeemableRewardType.FreeRound]: 'Standard free round',
	[RedeemableRewardType.DiscountedRound]: 'Points + cash',
};

const finalizeCheckInMutation = gql(`
mutation finalizeCheckIn($reservationId: String!, $creditCardId: String!, $rewardType: RedeemableRewardType) {
	finalizeCheckIn(reservationId: $reservationId, creditCardId: $creditCardId, rewardType: $rewardType) {
		pointsEarned
		reservation {
			id
			state
		}
	}
}`);

const createCheckIn = (trackEvent: Track, addToast: AddToast) =>
	action(async (data: FormData) => {
		const reservationId = data.get('reservationId') as string;
		const courseId = data.get('courseId') as string;
		const rewardType = data.get('rewardType') === '' ? undefined : (data.get('rewardType') as RedeemableRewardType);

		trackEvent('checkInRequest', { reservationId, courseId });

		const res = await getApiClient().mutation(finalizeCheckInMutation, {
			reservationId,
			creditCardId: data.get('creditCardId') as string,
			rewardType,
		});

		if (!res.error && res.data) {
			trackEvent('checkInSuccess', { reservationId, courseId });
			addToast('You’re all checked in.', { variant: 'positive' });
			await revalidate(['reservationInformation', 'allReservations']);
			throw redirect(`/reservation/${data.get('reservationId')}`);
		} else {
			trackEvent('checkInFailure', { reservationId, courseId });
		}

		return res;
	}, 'create-checkin');
